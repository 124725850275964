import { globalSearch } from "/js/store/index.js"
import { createApp } from '/js/petite-vue/dist/petite-vue.es.js';
import { createAtom } from '/js/vendor/xoid.custom.js';
import { reactiveAtom } from "/js/reactiveAtom.js"
import { Modal } from "/js/vendor/bootstrap.custom.js"
import { debounce } from '/js/utils.js';
import { isEmptyObject, mqMatches } from "/js/utils.js";
import { catalogApi } from '/js/api.js'


let searchForm = document.querySelector("#catalog-search")
let searchFormModal = document.querySelector("#catalog-search-modal")
let sfBackdrop = searchForm.querySelector(".catalog-nav__search-backdrop")

const searchVisible = createAtom(false)
const isMobile = mqMatches.focus('screenLgMax')

/* Показ/скрытие поиска */
function hideSearch(e) {
	searchForm.classList.remove("open")
	searchVisible.set(false)
}

function showSearch(e) {
	if (isMobile.value) {
		e.preventDefault();
		Modal.getOrCreateInstance(searchFormModal).show();
	}
	else {
		searchForm.classList.add("open")
	}
	searchVisible.set(true)
}

searchForm.addEventListener("focusin", showSearch, { capture: true })
sfBackdrop.addEventListener("click", hideSearch, { capture: true })

searchForm.addEventListener("focusout", (e) => {
	if (e.relatedTarget?.closest('#catalog-search') === null && !isMobile.value) hideSearch(e);
})
document.addEventListener("keyup", (e) => {
	if (e.key === "Escape" && searchForm.classList.contains("open")) {
		hideSearch(e)
		document.getElementById('catalog-search-btn').focus();
	};
})

isMobile.watch(val => {
	if (val) {
		searchForm.addEventListener("submit", showSearch)
		hideSearch()
	}
	else {
		searchForm.removeEventListener("submit", showSearch)
	}
})

/* фокус на поле ввода в окне поиска */
searchFormModal.addEventListener('shown.bs.modal', event => {
  event.target.querySelector('input[type="search"]').focus()
})
searchFormModal.addEventListener('hide.bs.modal', event => {
  searchVisible.set(false)
})


// Логика работы поиска
const isLoading = createAtom(false)

const query = createAtom('', atom => ({
	setQuery: debounce(500, val => atom.set(val))
}))

const results = createAtom({}, atom => ({
	getSuggests: () => {
		if (query.value.length > 2 && searchVisible.value) {
			isLoading.set(true)
			catalogApi.getSearchSuggests(query.value).then(resp => {
				if (resp?.status === 'Success') atom.set(resp.result);
			})
			.finally(() => isLoading.set(false) )
		}
	}
}))

const resultsEmpty = createAtom(read => {
	return isEmptyObject(read(results))
})

query.subscribe((nv, ov) => {
	if (nv.trim() !== ov.trim()) results.actions.getSuggests();
})
searchVisible.subscribe(results.actions.getSuggests)

globalSearch.watch(q => {
	if (q?.length) query.set(q);
})


const store = createAtom(read => ({
	query: read(query),
	results: read(results),
	resultsEmpty: read(resultsEmpty),
	isLoading: read(isLoading)
}))


createApp({
	setQuery: query.actions.setQuery,
	store: reactiveAtom(store),
	$delimiters: ['[[', ']]'],
}).mount(searchForm.querySelector('[role="search"]'))

createApp({
	setQuery: query.actions.setQuery,
	store: reactiveAtom(store),
	$delimiters: ['[[', ']]'],
}).mount(searchFormModal.querySelector('[role="search"]'))
